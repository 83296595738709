import { default as contactMEAEjuNaeNMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/contact.vue?macro=true";
import { default as content_45monitoring_45policyQTt8OYKI6eMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/content-monitoring-policy.vue?macro=true";
import { default as creation_detail2YmpTuolscMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/creation_detail.vue?macro=true";
import { default as face_45swap_45photoC7KTsEyhxVMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/face-swap-photo.vue?macro=true";
import { default as face_45swap_45videozHERyyjXTvMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/face-swap-video.vue?macro=true";
import { default as face_45swapTpwOsdme6CMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/face-swap.vue?macro=true";
import { default as indexcOAnrHA7G2Meta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/index.vue?macro=true";
import { default as _91_91site_93_93OWRhwBpFMfMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/login/[[site]].vue?macro=true";
import { default as accountgthwnd9eYeMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/my/account.vue?macro=true";
import { default as creationsQoZhTZrVO6Meta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/my/creations.vue?macro=true";
import { default as creditsf18ip59CaTMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/my/credits.vue?macro=true";
import { default as facesPRca3pJfiyMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/my/faces.vue?macro=true";
import { default as uploadsn7WJPu9s37Meta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/my/uploads.vue?macro=true";
import { default as myLDFV6AWLqkMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/my.vue?macro=true";
import { default as password_45changeTwbWpdc1HqMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/password-change.vue?macro=true";
import { default as password_45resetfmtMcFh9jWMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/password-reset.vue?macro=true";
import { default as payment_45failedxe5DacI7tHMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/payment-failed.vue?macro=true";
import { default as payment_45successIpsniRq9osMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/payment-success.vue?macro=true";
import { default as policyt83tnIV8vVMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/policy.vue?macro=true";
import { default as pricingh2dlHEQWY4Meta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/pricing.vue?macro=true";
import { default as signupRoJGX7ljvjMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/signup.vue?macro=true";
import { default as termssSvMzi58YbMeta } from "/Users/jackie/Projects/zl/bestFaceSwap/pages/terms.vue?macro=true";
export default [
  {
    name: contactMEAEjuNaeNMeta?.name ?? "contact___en",
    path: contactMEAEjuNaeNMeta?.path ?? "/contact",
    meta: contactMEAEjuNaeNMeta || {},
    alias: contactMEAEjuNaeNMeta?.alias || [],
    redirect: contactMEAEjuNaeNMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: content_45monitoring_45policyQTt8OYKI6eMeta?.name ?? "content-monitoring-policy___en",
    path: content_45monitoring_45policyQTt8OYKI6eMeta?.path ?? "/content-monitoring-policy",
    meta: content_45monitoring_45policyQTt8OYKI6eMeta || {},
    alias: content_45monitoring_45policyQTt8OYKI6eMeta?.alias || [],
    redirect: content_45monitoring_45policyQTt8OYKI6eMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/content-monitoring-policy.vue").then(m => m.default || m)
  },
  {
    name: creation_detail2YmpTuolscMeta?.name ?? "creation_detail___en",
    path: creation_detail2YmpTuolscMeta?.path ?? "/creation_detail",
    meta: creation_detail2YmpTuolscMeta || {},
    alias: creation_detail2YmpTuolscMeta?.alias || [],
    redirect: creation_detail2YmpTuolscMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/creation_detail.vue").then(m => m.default || m)
  },
  {
    name: face_45swap_45photoC7KTsEyhxVMeta?.name ?? "face-swap-photo___en",
    path: face_45swap_45photoC7KTsEyhxVMeta?.path ?? "/face-swap-photo",
    meta: face_45swap_45photoC7KTsEyhxVMeta || {},
    alias: face_45swap_45photoC7KTsEyhxVMeta?.alias || [],
    redirect: face_45swap_45photoC7KTsEyhxVMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/face-swap-photo.vue").then(m => m.default || m)
  },
  {
    name: face_45swap_45videozHERyyjXTvMeta?.name ?? "face-swap-video___en",
    path: face_45swap_45videozHERyyjXTvMeta?.path ?? "/face-swap-video",
    meta: face_45swap_45videozHERyyjXTvMeta || {},
    alias: face_45swap_45videozHERyyjXTvMeta?.alias || [],
    redirect: face_45swap_45videozHERyyjXTvMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/face-swap-video.vue").then(m => m.default || m)
  },
  {
    name: face_45swapTpwOsdme6CMeta?.name ?? "face-swap___en",
    path: face_45swapTpwOsdme6CMeta?.path ?? "/face-swap",
    meta: face_45swapTpwOsdme6CMeta || {},
    alias: face_45swapTpwOsdme6CMeta?.alias || [],
    redirect: face_45swapTpwOsdme6CMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/face-swap.vue").then(m => m.default || m)
  },
  {
    name: indexcOAnrHA7G2Meta?.name ?? "index___en",
    path: indexcOAnrHA7G2Meta?.path ?? "/",
    meta: indexcOAnrHA7G2Meta || {},
    alias: indexcOAnrHA7G2Meta?.alias || [],
    redirect: indexcOAnrHA7G2Meta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91site_93_93OWRhwBpFMfMeta?.name ?? "login-site___en",
    path: _91_91site_93_93OWRhwBpFMfMeta?.path ?? "/login/:site?",
    meta: _91_91site_93_93OWRhwBpFMfMeta || {},
    alias: _91_91site_93_93OWRhwBpFMfMeta?.alias || [],
    redirect: _91_91site_93_93OWRhwBpFMfMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/login/[[site]].vue").then(m => m.default || m)
  },
  {
    name: myLDFV6AWLqkMeta?.name ?? "my___en",
    path: myLDFV6AWLqkMeta?.path ?? "/my",
    meta: myLDFV6AWLqkMeta || {},
    alias: myLDFV6AWLqkMeta?.alias || [],
    redirect: myLDFV6AWLqkMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/my.vue").then(m => m.default || m),
    children: [
  {
    name: accountgthwnd9eYeMeta?.name ?? "my-account___en",
    path: accountgthwnd9eYeMeta?.path ?? "account",
    meta: accountgthwnd9eYeMeta || {},
    alias: accountgthwnd9eYeMeta?.alias || [],
    redirect: accountgthwnd9eYeMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/my/account.vue").then(m => m.default || m)
  },
  {
    name: creationsQoZhTZrVO6Meta?.name ?? "my-creations___en",
    path: creationsQoZhTZrVO6Meta?.path ?? "creations",
    meta: creationsQoZhTZrVO6Meta || {},
    alias: creationsQoZhTZrVO6Meta?.alias || [],
    redirect: creationsQoZhTZrVO6Meta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/my/creations.vue").then(m => m.default || m)
  },
  {
    name: creditsf18ip59CaTMeta?.name ?? "my-credits___en",
    path: creditsf18ip59CaTMeta?.path ?? "credits",
    meta: creditsf18ip59CaTMeta || {},
    alias: creditsf18ip59CaTMeta?.alias || [],
    redirect: creditsf18ip59CaTMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/my/credits.vue").then(m => m.default || m)
  },
  {
    name: facesPRca3pJfiyMeta?.name ?? "my-faces___en",
    path: facesPRca3pJfiyMeta?.path ?? "faces",
    meta: facesPRca3pJfiyMeta || {},
    alias: facesPRca3pJfiyMeta?.alias || [],
    redirect: facesPRca3pJfiyMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/my/faces.vue").then(m => m.default || m)
  },
  {
    name: uploadsn7WJPu9s37Meta?.name ?? "my-uploads___en",
    path: uploadsn7WJPu9s37Meta?.path ?? "uploads",
    meta: uploadsn7WJPu9s37Meta || {},
    alias: uploadsn7WJPu9s37Meta?.alias || [],
    redirect: uploadsn7WJPu9s37Meta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/my/uploads.vue").then(m => m.default || m)
  }
]
  },
  {
    name: password_45changeTwbWpdc1HqMeta?.name ?? "password-change___en",
    path: password_45changeTwbWpdc1HqMeta?.path ?? "/password-change",
    meta: password_45changeTwbWpdc1HqMeta || {},
    alias: password_45changeTwbWpdc1HqMeta?.alias || [],
    redirect: password_45changeTwbWpdc1HqMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/password-change.vue").then(m => m.default || m)
  },
  {
    name: password_45resetfmtMcFh9jWMeta?.name ?? "password-reset___en",
    path: password_45resetfmtMcFh9jWMeta?.path ?? "/password-reset",
    meta: password_45resetfmtMcFh9jWMeta || {},
    alias: password_45resetfmtMcFh9jWMeta?.alias || [],
    redirect: password_45resetfmtMcFh9jWMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/password-reset.vue").then(m => m.default || m)
  },
  {
    name: payment_45failedxe5DacI7tHMeta?.name ?? "payment-failed___en",
    path: payment_45failedxe5DacI7tHMeta?.path ?? "/payment-failed",
    meta: payment_45failedxe5DacI7tHMeta || {},
    alias: payment_45failedxe5DacI7tHMeta?.alias || [],
    redirect: payment_45failedxe5DacI7tHMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: payment_45successIpsniRq9osMeta?.name ?? "payment-success___en",
    path: payment_45successIpsniRq9osMeta?.path ?? "/payment-success",
    meta: payment_45successIpsniRq9osMeta || {},
    alias: payment_45successIpsniRq9osMeta?.alias || [],
    redirect: payment_45successIpsniRq9osMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/payment-success.vue").then(m => m.default || m)
  },
  {
    name: policyt83tnIV8vVMeta?.name ?? "policy___en",
    path: policyt83tnIV8vVMeta?.path ?? "/policy",
    meta: policyt83tnIV8vVMeta || {},
    alias: policyt83tnIV8vVMeta?.alias || [],
    redirect: policyt83tnIV8vVMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: pricingh2dlHEQWY4Meta?.name ?? "pricing___en",
    path: pricingh2dlHEQWY4Meta?.path ?? "/pricing",
    meta: pricingh2dlHEQWY4Meta || {},
    alias: pricingh2dlHEQWY4Meta?.alias || [],
    redirect: pricingh2dlHEQWY4Meta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: signupRoJGX7ljvjMeta?.name ?? "signup___en",
    path: signupRoJGX7ljvjMeta?.path ?? "/signup",
    meta: signupRoJGX7ljvjMeta || {},
    alias: signupRoJGX7ljvjMeta?.alias || [],
    redirect: signupRoJGX7ljvjMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: termssSvMzi58YbMeta?.name ?? "terms___en",
    path: termssSvMzi58YbMeta?.path ?? "/terms",
    meta: termssSvMzi58YbMeta || {},
    alias: termssSvMzi58YbMeta?.alias || [],
    redirect: termssSvMzi58YbMeta?.redirect,
    component: () => import("/Users/jackie/Projects/zl/bestFaceSwap/pages/terms.vue").then(m => m.default || m)
  }
]